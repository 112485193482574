<template>
  <div
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div class="mobile-only" style="margin: 10px">
      <v-btn
        class="mt-10 d-sm-none text-none"
        @click="redirectPhotoShooter"
        :disabled="uploading"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </div>
    <div class="mobile-container">
      <h2
        class="text-center mb-2"
        :style="{
          styleTitle,
          fontFamily: 'Georama',
          fontSize: 24,
          fontWeight: 700,
          marginTop: '5px',
        }"
      >
        Votre signature
      </h2>
      <div
        class="subtitle-1 text-center"
        style="
          font-family: 'Georama';
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #00000;
          margin-top: 10px;
        "
      >
        Signez avec votre doigt à
      </div>
      <div
        class="subtitle-1 text-center"
        style="
          font-family: 'Georama';
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #00000;
        "
      >
        l'intérieur du cadre
      </div>
      <div class="d-flex justify-center mb-4 mt-8">
        <drawer
          ref="drawer"
          :style="{
            width: '90vw',
          }"
          :clear.sync="clearCanvas"
          @empty="canvasEmpty = $event"
          @end="send"
        ></drawer>
      </div>
      <div class="d-flex flex-column align-items-center">
        <v-btn
          :color="color4"
          class="mt-4"
          :disabled="canvasEmpty || uploading"
          @click="validate"
          :loading="uploading"
          :style="{
            color: buttonTextValidColor,
            borderRadius: '30px',
            'text-transform': 'none',
            padding: '25px 40px',
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '600',
            'line-height': '20px',
            'letter-spacing': '0em',
            'text-align': 'center',
          }"
          >Valider</v-btn
        >
        <v-btn
          :color="color5"
          :style="{
            color: color4,
            borderRadius: '30px',
            'text-transform': 'none',
            padding: '25px 40px',
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '600',
            'line-height': '20px',
            'letter-spacing': '0em',
            'text-align': 'center',
            marginTop: '20px',
          }"
          :disabled="canvasEmpty || uploading"
          @click="$refs.drawer.clearCanvas()"
        >
          Recommencer
        </v-btn>
      </div>
      <div
        v-show="uploading"
        class="text-center align-middle"
        style="height: 300px"
      >
        <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
        ></v-progress-circular>
        <br />
        <span>Veuillez patienter</span>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from '@/components/Drawer'
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'

export default {
  name: 'Signature',
  data() {
    return {
      clearCanvas: false,
      canvasEmpty: true,
      uploading: false,
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      color4: localStorageService.getStyle(location.hostname, 'color4'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    }
  },
  components: { Drawer },
  methods: {
    validate() {
      this.uploading = true
      this.$refs.drawer.validateCanvas()
      //this.$router.push({ name: 'TreatmentPhoto' })
    },
    redirectPhotoShooter() {
      this.$router.push({
        name: 'PhotoShooter',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    async send({ signatureUrl }) {
      if (
        localStorageService.getValue('rejectStatus') === 'rejected_signature'
      ) {
        //Only signature to Redo
        //Signature save
        const signatureFormData = new FormData()
        const signatureBlob = await (await fetch(signatureUrl)).blob()
        signatureFormData.append('file', signatureBlob)
        signatureFormData.append('type', 'signature')

        const signaturePromise = requestService
          .post(`/file/upload`, signatureFormData)
          .then(data => {
            localStorageService.setObject('signatureUid', data.data.uid)
          })

        Promise.all([signaturePromise])
          .then(() => {
            //order save
            const orderFormData = JSON.stringify({
              status: 'pending_verification',
              signature: {
                status: 'pending_verification',
                newSignatureUid: localStorageService.getValue('signatureUid'),
              },
            })
            requestService
              .post(
                '/document/' +
                  localStorageService.getValue('documentId') +
                  '/update',
                orderFormData,
                {},
                {
                  'content-type': 'application/json',
                  shortlive_api_key:
                    localStorageService.getValue('shortliveApiKey'),
                },
              )
              .then(() => {
                this.uploading = false
                this.$router.push({ name: 'Updated' })
              })
              .catch(() => {
                this.$store.dispatch(
                  'alert/error',
                  'Erreur lors de la création de la commande.',
                )
                this.uploading = false
                this.$router.push({ name: 'NotAuthorized' })
              })
          })
          .catch(() => {
            this.$store.dispatch(
              'alert/error',
              "Erreur lors de l'envoi de vos documents.",
            )
            this.uploading = false
            this.$router.push({ name: 'NotAuthorized' })
          })
      } else {
        //Signature save
        const signatureFormData = new FormData()
        const signatureBlob = await (await fetch(signatureUrl)).blob()
        signatureFormData.append('file', signatureBlob)
        signatureFormData.append('type', 'signature')

        const signaturePromise = requestService
          .post(`/file/upload`, signatureFormData)
          .then(data => {
            localStorageService.setObject('signatureUid', data.data.uid)
          })

        //Photo save
        const photoFormData = new FormData()
        const photoBlob = await (
          await fetch(localStorageService.getValue('photoUrl'))
        ).blob()
        photoFormData.append('file', photoBlob)
        photoFormData.append('type', 'photo')

        const photoPromise = requestService
          .post(`/file/upload`, photoFormData)
          .then(data => {
            localStorageService.setObject('photoUid', data.data.uid)
          })

        Promise.all([photoPromise, signaturePromise])
          .then(() => {
            //order save
            const orderFormData = JSON.stringify({
              orderItems: [
                {
                  quantity: 1,
                  document: {
                    photo: localStorageService.getValue('photoUid'),
                    signature: localStorageService.getValue('signatureUid'),
                  },
                  priceId: localStorageService.getValue('priceId'),
                },
              ],
            })
            requestService
              .post(
                `/order/stripeCheckout`,
                orderFormData,
                {},
                {
                  'content-type': 'application/json',
                  shortlive_api_key:
                    localStorageService.getValue('shortliveApiKey'),
                },
              )
              .then(data => {
                localStorageService.setObject('orderId', data.data.id)
                localStorageService.setObject(
                  'checkoutSessionId',
                  data.data.checkoutSessionId,
                )
                if (data.data.amount == 0) {
                  this.uploading = false
                  this.$router.push({ name: 'Final' })
                } else if (
                  data.data.amount == null ||
                  data.data.amount == 'null'
                ) {
                  this.uploading = false
                  this.$router.push({ name: 'AlertAgency' })
                } else {
                  this.uploading = false
                  this.$router.push({ name: 'Payment' })
                  //this.$router.push({ name: 'TreatmentPhoto' })
                }
              })
              .catch(() => {
                this.$store.dispatch(
                  'alert/error',
                  'Erreur lors de la création de la commande.',
                )
                this.uploading = false
                this.$router.push({ name: 'NotAuthorized' })
              })
          })
          .catch(() => {
            this.$store.dispatch(
              'alert/error',
              "Erreur lors de l'envoi de vos documents.",
            )
            this.uploading = false
            this.$router.push({ name: 'NotAuthorized' })
          })
      }
    },
  },
  mounted() {
    if (localStorageService.getValue('rejectStatus') !== 'rejected_signature') {
      if (localStorageService.getValue('shortliveApiKey') === undefined) {
        this.uploading = false
        this.$router.push({ name: 'NotAuthorized' })
      } else if (localStorageService.getValue('photoUrl') === undefined) {
        this.uploading = false
        this.$router.push({ name: 'ShootPhoto' })
      }
    }
  },
}
</script>

<style scoped>
.mobile-only {
  margin: 10px;
}

.mobile-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .mobile-container {
    width: 90%;
  }
}
</style>
