<template>
  <canvas
    class="draw-canvas"
    :class="{ client: client }"
    ref="drawCanvas"
    width="521"
    height="134"
    @mousemove="draw"
    @mousedown="beginDrawing"
    @mouseup="stopDrawing"
    @touchstart="preventDefault"
    @touchend="preventDefault"
    @touchmove="preventDefault"
    v-touch="{ start: beginDrawing, end: stopDrawing, move: draw }"
    v-bind="$attrs"
  />
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Drawer',
  props: {
    client: Boolean,
    clear: Boolean,
    validate: Boolean,
  },
  data() {
    return {
      canvasCtx: null,
      x: 0,
      y: 0,
      isDrawing: false,
      socket: null,
      postId: null,
      needEmail: true,
      empty: true,
    }
  },
  computed: {
    ...mapState({
      sharedCanvasData: state => state.canvasData,
    }),
  },
  methods: {
    drawLine(x1, y1, x2, y2) {
      const ctx = this.canvasCtx
      ctx.beginPath()
      ctx.strokeStyle = 'black'
      ctx.lineWidth = 2
      ctx.moveTo(x1, y1)
      ctx.lineTo(x2, y2)
      ctx.stroke()
      ctx.closePath()
      this.empty = false
    },
    draw(e) {
      if (!this.isDrawing) {
        return
      }
      const offsetX = this.getOffsetX(e)
      const offsetY = this.getOffsetY(e)
      this.drawLine(this.x, this.y, offsetX, offsetY)
      this.x = offsetX
      this.y = offsetY
    },
    beginDrawing(e) {
      if (this.client) {
        return
      }
      const offsetX = this.getOffsetX(e)
      const offsetY = this.getOffsetY(e)
      this.x = offsetX
      this.y = offsetY
      this.isDrawing = true
    },
    stopDrawing(e) {
      if (this.isDrawing) {
        const offsetX = this.getOffsetX(e)
        const offsetY = this.getOffsetY(e)
        this.drawLine(this.x, this.y, offsetX, offsetY)
        this.x = 0
        this.y = 0
        this.isDrawing = false
      }
    },
    getOffsetX(e) {
      let offsetX = e.offsetX
      if (e.changedTouches) {
        const position = e.target.getBoundingClientRect()
        offsetX = e.changedTouches[0].pageX - position.left
      }
      return Math.round((e.target.width / e.target.offsetWidth) * offsetX)
    },
    getOffsetY(e) {
      let offsetY = e.offsetY
      if (e.changedTouches) {
        const position = e.target.getBoundingClientRect()
        offsetY = e.changedTouches[0].clientY - position.top
      }
      return Math.round((e.target.height / e.target.offsetHeight) * offsetY)
    },

    clearCanvas() {
      this.canvasCtx.clearRect(
        0,
        0,
        this.$refs.drawCanvas.width,
        this.$refs.drawCanvas.height,
      )
      const canvas = this.$refs.drawCanvas
      this.canvasCtx.fillStyle = 'white'
      this.canvasCtx.fillRect(0, 0, canvas.width, canvas.height)
      this.empty = true
    },
    validateCanvas() {
      const signatureUrl = this.canvasCtx.canvas.toDataURL('image/jpeg', 1)
      const signatureData = this.canvasCtx.getImageData(
        0,
        0,
        this.canvasCtx.canvas.width,
        this.canvasCtx.canvas.height,
      )
      this.$emit('end', { signatureUrl, signatureData })
      /*this.$store.dispatch('canvas/signatureFinished', {
         canvasData: signatureData,
       })*/
    },
    preventDefault(e) {
      e.preventDefault()
    },
  },
  watch: {
    clear(value) {
      if (value) {
        this.clearCanvas()
        this.$emit('update:clear', false)
      }
    },
    validate(value) {
      if (value) {
        this.validateCanvas()
        this.$emit('update:validate', false)
      }
    },
    sharedCanvasData(value) {
      if (this.client && value !== null) {
        this.canvasCtx.putImageData(value, 0, 0)
      }
    },
    empty(value) {
      this.$emit('empty', value)
    },
  },
  mounted() {
    const canvas = this.$refs.drawCanvas
    this.canvasCtx = canvas.getContext('2d')
    //if (this.sharedCanvasData !== null) {
    //  this.canvasCtx.putImageData(this.sharedCanvasData, 0, 0)
    //} else {
    this.canvasCtx.fillStyle = 'white'
    this.canvasCtx.fillRect(0, 0, canvas.width, canvas.height)
    //}
  },
}
</script>

<style scoped lang="scss">
.draw-canvas {
  border: 1px solid black;
  padding: 1px;
  &:not(.client) {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 10px;
  }
}
</style>
