var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"mobile-only",staticStyle:{"margin":"10px"}},[_c('v-btn',{staticClass:"mt-10 d-sm-none text-none",style:({
        'font-family': 'Georama',
        'font-size': '16px',
        'font-weight': '400',
        'line-height': '20px',
        'letter-spacing': '0em',
        padding: '5px 10px',
      }),attrs:{"disabled":_vm.uploading,"outlined":""},on:{"click":_vm.redirectPhotoShooter}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Retour ")],1)],1),_c('div',{staticClass:"mobile-container"},[_c('h2',{staticClass:"text-center mb-2",style:({
        styleTitle: _vm.styleTitle,
        fontFamily: 'Georama',
        fontSize: 24,
        fontWeight: 700,
        marginTop: '5px',
      })},[_vm._v(" Votre signature ")]),_c('div',{staticClass:"subtitle-1 text-center",staticStyle:{"font-family":"'Georama'","font-weight":"400","font-size":"16px","line-height":"20px","text-align":"center","color":"#00000","margin-top":"10px"}},[_vm._v(" Signez avec votre doigt à ")]),_c('div',{staticClass:"subtitle-1 text-center",staticStyle:{"font-family":"'Georama'","font-weight":"400","font-size":"16px","line-height":"20px","text-align":"center","color":"#00000"}},[_vm._v(" l'intérieur du cadre ")]),_c('div',{staticClass:"d-flex justify-center mb-4 mt-8"},[_c('drawer',{ref:"drawer",style:({
          width: '90vw',
        }),attrs:{"clear":_vm.clearCanvas},on:{"update:clear":function($event){_vm.clearCanvas=$event},"empty":function($event){_vm.canvasEmpty = $event},"end":_vm.send}})],1),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('v-btn',{staticClass:"mt-4",style:({
          color: _vm.buttonTextValidColor,
          borderRadius: '30px',
          'text-transform': 'none',
          padding: '25px 40px',
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '600',
          'line-height': '20px',
          'letter-spacing': '0em',
          'text-align': 'center',
        }),attrs:{"color":_vm.color4,"disabled":_vm.canvasEmpty || _vm.uploading,"loading":_vm.uploading},on:{"click":_vm.validate}},[_vm._v("Valider")]),_c('v-btn',{style:({
          color: _vm.color4,
          borderRadius: '30px',
          'text-transform': 'none',
          padding: '25px 40px',
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '600',
          'line-height': '20px',
          'letter-spacing': '0em',
          'text-align': 'center',
          marginTop: '20px',
        }),attrs:{"color":_vm.color5,"disabled":_vm.canvasEmpty || _vm.uploading},on:{"click":function($event){return _vm.$refs.drawer.clearCanvas()}}},[_vm._v(" Recommencer ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploading),expression:"uploading"}],staticClass:"text-center align-middle",staticStyle:{"height":"300px"}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":""}}),_c('br'),_c('span',[_vm._v("Veuillez patienter")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }